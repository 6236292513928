// When we are ready for login/logout functionality:
// https://jasonwatmore.com/post/2020/03/02/react-hooks-redux-user-registration-and-login-tutorial-example
// https://serverless-stack.com/chapters/create-a-login-page.html

import { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import CreateAccount from '../auth/CreateAccount';
import Login from '../auth/Login';
import Logout from '../auth/Logout';

import {
  APPROVE,
  APPROVED_WITH_EDITS,
  APPROVED_NO_EDITS,
} from '../../constants';

import TransitionsModal from './TransitionModal';

const firestore = firebase.firestore();

export default function ApproveModal({
  modalStates,
  setModalStates,
  profileEditData,
  edit,
  nonprofitId,
  createAction,
  profile,
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const [user, setUser] = useState(null);
  const [name, setName] = useState('');

  const [error, setError] = useState();
  const [authState, setAuthState] = useState('sign-up');

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  // when authState (login or sign up) changes, clear password fields and clear errors
  useEffect(() => {
    setError();
    setPassword('');
    setLoginPassword('');
  }, [authState]);

  // when user state changes, clear password fields and clear errors
  // if user is logged in, automatically approve actions
  useEffect(() => {
    if (user) {
      setPassword('');
      setLoginPassword('');
    }
  }, [user]);

  // setting the user in the callback (check where onAuthStateChange is called below)
  function onAuthStateChange(callback) {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        callback(user);
      } else {
        callback(null);
      }
    });
  }

  // login functionality
  function login(username, password) {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      // clear errors on success
      .then(() => setError())
      // set error on failure
      .catch((error) => setError(error.message));
  }

  // create account functionality
  function createAccount(username, password) {
    if (!name) {
      // error handling around requiring name
      setError('Please provide a name.');
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(username, password)
        // clear errors on success
        .then((userCreds) => {
          const userId = userCreds.user.uid;
          const userEmail = userCreds.user.email;
          firestore
            .collection('/users')
            .doc(userId)
            .set({
              userId: userId,
              name: name,
              email: userEmail,
              created: new Date(),
              shareInfoWithNonprofits: true,
              userBalance: 0,
            })
            .then(() => {
              firestore
                .collection('/users')
                .doc(userId)
                .collection('nonprofits')
                .doc(nonprofitId)
                .set({
                  nonprofitId: nonprofitId,
                  nonprofitPermissions: 'Admin',
                });
            })
            .then(() => {})
            .catch((error) => setError(error.message));
          setError();
        })
        // set error on failure
        .catch((error) => setError(error.message));
    }
  }

  // logout functionality
  function logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // clear errors on success
        setError();
      })
      .catch((error) => {
        // set error on failure
        setError(error.message);
      });
  }

  // formatting form data from edit field into what firestore function is expecting
  const formatNonprofitData = (profileData) => {
    const formattedProfile = {
      name: profileData.name,
      address: {
        streetAddress1: profileData.streetAddress1,
        streetAddress2: profileData.streetAddress2,
        city: profileData.city,
        zipCode: profileData.zipCode,
        state: profileData.state,
      },
      taxId: profileData.taxId,
      logo: profileData.logo,
      websiteUrl: profileData.websiteUrl,
      facebookUrl: profileData.facebookUrl,
      email: profileData.email,
      description: profileData.description,
      category: profileData.categories,
      metro: profileData.metroAreas,
      nonprofitId: nonprofitId,
      needs: {
        needTitle1: profileData.needTitle1,
        needAmount1: profileData.needAmount1,
        needDescription1: profileData.needDescription1,
        needImage1: profileData.needImage1,
        needId1: profileData.needId1,
        needTitle2: profileData.needTitle2,
        needAmount2: profileData.needAmount2,
        needDescription2: profileData.needDescription2,
        needImage2: profileData.needImage2,
        needId2: profileData.needId2,
        needTitle3: profileData.needTitle3,
        needAmount3: profileData.needAmount3,
        needDescription3: profileData.needDescription3,
        needImage3: profileData.needImage3,
        needId3: profileData.needId3,
      },
    };
    return formattedProfile;
  };

  const approveAction = () => {
    // suppressing a weird bug
    if (profile && user) {
      // if we are editing, submit a "mutation" - defined in src/components/NonprofitActionHandler.js
      if (edit) {
        createAction.mutate({
          actionPayload: {
            nonprofitId: nonprofitId,
            action: APPROVED_WITH_EDITS,
            nonprofitName: profile.name,
            submitterName: name,
            email: user.email,
            edits: formatNonprofitData(profileEditData),
          },
        });
      } else {
        createAction.mutate({
          actionPayload: {
            nonprofitId: nonprofitId,
            action: APPROVED_NO_EDITS,
            nonprofitName: profile.name,
            submitterName: name,
            email: user.email,
          },
        });
      }
    }

    // close the modal once action is created
    setModalStates({ type: APPROVE, open: false });
  };

  // form handling
  const handleSubmit = (e) => {
    // let react handle form submission
    e.preventDefault();

    // if user is logged in, approve when form is submitted
    if (user) {
      approveAction();
    } else {
      // if we are showing the sign-up form, create an account for the user (before approveAction is called once the user state changes at the top)
      if (authState === 'sign-up') {
        createAccount(email, password);
      } else {
        // if we are showing the log-in form, log in the user (before approveAction is called once the user state changes at the top)
        login(loginUsername, loginPassword);
      }
    }
  };

  return (
    <TransitionsModal open={modalStates.approve}>
      <form onSubmit={handleSubmit}>
        <h3 id="transition-modal-title" style={{ paddingTop: 20 }}>
          Approve this profile, looks good
        </h3>

        {/* what to show if the user is not logged in */}
        {!user && (
          <>
            {/* what to show if authState is 'login' */}
            {authState === 'login' && (
              <Login
                loginUsername={loginUsername}
                setLoginUsername={setLoginUsername}
                loginPassword={loginPassword}
                setLoginPassword={setLoginPassword}
                setAuthState={setAuthState}
              />
            )}

            {/* what to show if authState is 'sign-up' */}
            {authState === 'sign-up' && (
              <CreateAccount
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                setAuthState={setAuthState}
              />
            )}

            {/* showing an error if the user did something wrong */}
            {error && <Alert severity="error">{error}</Alert>}
          </>
        )}

        {/* what to show if the user is logged in  */}
        {user && <Logout user={user} onClick={logout} />}

        <div style={{ paddingTop: 10 }}>
          <span>
            <button type="submit" className={`btn w-button`} onClick={() => {}}>
              <span>Confirm</span>
            </button>
          </span>
          <button
            type="button"
            className="btn btn-secondary w-button"
            onClick={() => {
              // clear errors if the user is canceling
              setError();
              // close the modal
              setModalStates({ type: APPROVE, open: false });
            }}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </TransitionsModal>
  );
}
