// When we are ready for login/logout functionality:
// https://jasonwatmore.com/post/2020/03/02/react-hooks-redux-user-registration-and-login-tutorial-example
// https://serverless-stack.com/chapters/create-a-login-page.html

import React, { useState } from 'react';
import styled from '@emotion/styled';
import Alert from '@material-ui/lab/Alert';

import TransitionsModal from './TransitionModal';
import { ButtonContainer } from '../Styled';

import Input from '../Input';
import { CONTACT_ME_MODAL, CONTACT_ME_ACTION } from '../../constants';

const StyledMessage = styled.div`
  padding: 20px 0;
`;

export default function ContactMeModal({
  modalStates,
  createAction,
  profile,
  nonprofitId,
  setModalStates,
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState();

  const validate = () => {
    if (!name || !email || !phone) {
      setError('Contact name, email, and phone numbers are required.');
      return false;
    }
    return true;
  };

  const callAction = (e) => {
    e.preventDefault();

    const isValid = validate();

    if (isValid) {
      createAction.mutate({
        actionPayload: {
          nonprofitId: nonprofitId,
          action: CONTACT_ME_ACTION,
          phone: phone,
          nonprofitName: profile.name,
          submitterName: name,
          email: email,
        },
      });

      // reset defaults
      setName('');
      setEmail('');
      setPhone('');
      setError();

      setModalStates({ type: CONTACT_ME_MODAL, open: false });
    }
  };

  return (
    <TransitionsModal open={modalStates.call}>
      <form onSubmit={callAction}>
        <h3 id="transition-modal-title">Please contact me</h3>
        <Input
          label="Your name"
          type="text"
          name="name"
          value={name}
          onChange={(name) => setName(name)}
        />
        <Input
          label="Your email"
          type="email"
          name="username"
          value={email}
          onChange={(email) => setEmail(email)}
        />
        <Input
          label="Your phone"
          type="text"
          name="phone"
          value={phone}
          onChange={(phone) => setPhone(phone)}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <StyledMessage>
          A Grateful representative will contact you about your profile!
        </StyledMessage>

        <ButtonContainer buttonWidth="80px">
          <button type="submit" className="btn w-button">
            <span>Confirm</span>
          </button>
          <button
            className="btn btn-secondary w-button"
            type="reset"
            onClick={() => {
              setError(false);
              setModalStates({ type: CONTACT_ME_MODAL, open: false });
            }}
          >
            <span>Cancel</span>
          </button>
        </ButtonContainer>
      </form>
    </TransitionsModal>
  );
}
