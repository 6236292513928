// TODO: Change this to change all the instances of this URL in the app
export const GRATEFUL_APP_URL = 'https://app.begrateful.org';
export const GRATEFUL_FAQ_URL = 'https://www.begrateful.org/nonprofits#faq';
export const GRATEFUL_TWITTER_URL = 'https://twitter.com/gratefulapp';
export const GRATEFUL_LINKEDIN_URL =
  'https://www.linkedin.com/company/gratefulapp/';
export const GRATEFUL_APPLE_APP_URL =
  'https://apps.apple.com/us/app/given/id1454844119';
export const GRATEFUL_GOOGLE_APP_URL =
  'https://play.google.com/store/apps/details?id=app.given.Given';

export const GRATEFUL_EMAIL = 'help@begrateful.org';

// TODO: To load prod config in prod, swap out this string with Top Level Domain of PROD_URL
// This is used in `firebase.js`
export const PROD_URL = 'profile.begrateful.org';

// Modal Reducer Action types
export const APPROVE = 'APPROVE';
export const CONTACT_ME_MODAL = 'CONTACT_ME_MODAL';
export const CANCEL_EDIT = 'CANCEL_EDIT';
export const REMOVE_MODAL = 'REMOVE_MODAL';

// Action names put into edit call
export const APPROVED_WITH_EDITS = 'Approved With Edits';
export const APPROVED_NO_EDITS = 'Approved No Edits';
export const REMOVED_ACTION = 'Removed';
export const CONTACT_ME_ACTION = 'Contact Me';
