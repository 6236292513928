import firebase from 'firebase/app';
import 'firebase/storage';

import React, { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useQuery } from 'react-query';

import PillSelect from './PillSelect';
import NeedForm from './NeedForm';

import Input from './Input';
import ImageUpload from './ImageUpload';

export default function NonprofitProfileEdit({
  profile,
  profileEditData,
  setProfileEditData,
  setEditError,
}) {
  const {
    name,
    logo,
    streetAddress1,
    streetAddress2,
    city,
    zipCode,
    state,
    taxId,
    websiteUrl,
    facebookUrl,
    email,
    description,
    categories,
    needTitle1,
    needAmount1,
    needDescription1,
    needImage1,
    needId1,
    needTitle2,
    needAmount2,
    needDescription2,
    needImage2,
    needId2,
    needTitle3,
    needAmount3,
    needDescription3,
    needImage3,
    needId3,
  } = profileEditData;

  const validate = () => {
    let isValid = true;
    const editErrors = [];

    for (let i = 0; i < 3; i++) {
      const alertNeed = {};

      if (needTitles[i] && !needAmounts[i]) {
        editErrors.push(`Please add an amount for Ongoing Need #${i + 1}.\n`);
        alertNeed.amount = true;
        isValid = false;
      } else {
        alertNeed.amount = null;
      }

      if (needAmounts[i]) {
        if (!/^[0-9.,]+$/.test(needAmounts[i])) {
          editErrors.push(`Amount for Ongoing Need #${i + 1} should be numbers only, no other characters.\n`);
          alertNeed.numbersOnly = true;
          isValid = false;
        } else {
          alertNeed.numbersOnly = null;
        }
      } else {
        alertNeed.numbersOnly = null;
      }

      if (needTitles[i] && !needDescriptions[i]) {
        editErrors.push(
          `Please add a description for Ongoing Need #${i + 1}.\n`
        );
        alertNeed.description = true;
        isValid = false;
      } else {
        alertNeed.description = null;
      }

      if (needTitles[i] && !needImages[i]) {
        editErrors.push(
          `Please add an image or video for Ongoing Need #${i + 1}.\n`
        );
        alertNeed.file = true;
        isValid = false;
      } else {
        alertNeed.file = null;
      }

      if (needImages[i]) {
        const filename = needImages[i].toLowerCase();
        if (!filename.includes('jpg') && !filename.includes('jpeg') && !filename.includes('png') && !filename.includes('gif') && !filename.includes('mp4') && !filename.includes('mov')) {
          editErrors.push(
            `File for Ongoing Need #${i + 1} should be image (JPG, PNG, GIF) or video (MP4, MOV) only.\n`
          );
          alertNeed.fileType = true;
          isValid = false;
        } else {
          alertNeed.fileType = null;
        }
      } else {
        alertNeed.fileType = null;
      }

      if (
        (needAmounts[i] || needDescriptions[i] || needImages[i]) &&
        !needTitles[i]
      ) {
        editErrors.push(`Please add a title for Ongoing Need #${i + 1}.\n`);
        alertNeed.title = true;
        isValid = false;
      } else {
        alertNeed.title = null;
      }

      setAlertNeeds[i](alertNeed);
    }

    if (!isValid) {
      setEditError(editErrors);
      return false;
    } else {
      setEditError(null);
      return true;
    }
  };

  useEffect(() => {
    validate();
  }, [profileEditData]);

  // Used to get image uploaded in ImageUpload component
  const [image, setImage] = useState({ file: null, localUrl: null });
  const [editImage, setEditImage] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [alertNeed1, setAlertNeed1] = useState({});
  const [alertNeed2, setAlertNeed2] = useState({});
  const [alertNeed3, setAlertNeed3] = useState({});
  const alertNeeds = [alertNeed1, alertNeed2, alertNeed3];
  const setAlertNeeds = [setAlertNeed1, setAlertNeed2, setAlertNeed3];

  const needTitles = [needTitle1, needTitle2, needTitle3];
  const needDescriptions = [
    needDescription1,
    needDescription2,
    needDescription3,
  ];
  const needImages = [needImage1, needImage2, needImage3];
  const needAmounts = [needAmount1, needAmount2, needAmount3];

  // useQuery is a package to help with loading
  const { isLoading, error, data: categoryObjects } = useQuery(
    'categories',
    async () => {
      // uses local server if local, otherwise sets to function for Firebase mapping (see firebase.json: rewrites)
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? '/test-given-functions/us-central1'
          : '/function';
      const res = await fetch(`${baseUrl}/getCategories`);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const json = res.json();
      return json;
    }
  );

  useEffect(() => {
    if (categoryObjects) {
      setCategoryList(categoryObjects.map((category) => category.name));
    }
  }, [categoryObjects]);

  const resetUploadedImage = () => {
    setImage({ file: null, localUrl: null });
    setEditImage(false);
  };

  // if the user uploads an image, set the profileEditData to have the url for this new imageUpload
  // otherwise, revert back to old logo
  useEffect(() => {
    if (image.file) {
      const now = new Date().toISOString();
      const destination_blob_name = `nonprofit_images/${profile.name}-${now}-${image.file.name}`;
      const storageRef = firebase.storage().ref().child(destination_blob_name);
      storageRef.put(image.file).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then((url) => {
          setProfileEditData({ ...profileEditData, logo: url });
        });
      });
    } else {
      setProfileEditData({ ...profileEditData, logo: profile.logo });
    }
  }, [image.file]);

  useBeforeunload(
    () =>
      'Your changes will not be saved. Are you sure you want to navigate away?'
  );

  const updateNeedFile = (file, needKey) => {
    if (file) {
      const now = new Date().toISOString();
      const fileType = file.name.substring(file.name.length - 3).toLowerCase();
      const destination_blob_name =
        fileType === 'mov' || fileType === 'mp4'
          ? `nonprofit_videos/${profile.name}-${now}-Need-${file.name}`
          : `needs_images/${profile.name}-${now}-Need-${file.name}`;
      const storageRef = firebase.storage().ref().child(destination_blob_name);
      storageRef.put(file).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then((url) => {
          dynamicObjectUpdate(
            profileEditData,
            setProfileEditData,
            `needImage${needKey}`,
            url
          );
        });
      });
    } else {
      dynamicObjectUpdate(
        profileEditData,
        setProfileEditData,
        `needImage${needKey}`,
        profile[`needImage${needKey}`]
      );
    }
    validate();
  };

  const dynamicObjectUpdate = (objectToUpdate, setter, key, value) => {
    const newObject = { ...objectToUpdate };
    newObject[key] = value;
    setter(newObject);
  };

  const needFileChangeHandler = (file, needKey) => {
    if (file) {
      updateNeedFile(file, needKey);
    } else {
      dynamicObjectUpdate(
        profileEditData,
        setProfileEditData,
        `needImage${needKey}`,
        null
      );
      validate();
    }
  };

  return (
    <>
      <div className="card mock-profile_logo">
        <label className="input-label mock-profile_logo">Current Logo</label>
        <div className="mock-profile_logo-wrapper">
          <img
            src={logo}
            alt={`${name} Logo`}
            width={100}
            className="mock-profile_logo-img"
          />
          {!editImage && (
            <button
              className="btn btn-secondary w-button"
              onClick={() => setEditImage(true)}
            >
              Change Logo
            </button>
          )}
          {editImage && (
            <button
              className="btn btn-secondary w-button"
              onClick={() => resetUploadedImage()}
            >
              Cancel Change
            </button>
          )}
        </div>
        {editImage && (
          <>
            <label
              style={{ paddingTop: 10 }}
              className="input-label mock-profile_logo"
            >
              New Logo
            </label>
            <ImageUpload image={image} setImage={setImage} />
          </>
        )}
      </div>
      <Input
        label="Nonprofit Name"
        type="text"
        value={name}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, name: val })
        }
      />
      <Input
        label="Street Address 1"
        type="text"
        value={streetAddress1}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, streetAddress1: val })
        }
      />

      <Input
        label="Street Address 2"
        type="text"
        value={streetAddress2}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, streetAddress2: val })
        }
      />

      <Input
        label="City"
        type="text"
        value={city}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, city: val })
        }
      />

      <Input
        label="Zip Code"
        type="text"
        value={zipCode}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, zipCode: val })
        }
      />

      <Input
        label="State"
        type="text"
        value={state}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, state: val })
        }
      />

      <Input
        label="Tax ID"
        type="text"
        value={taxId}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, taxId: val })
        }
      />
      <Input
        label="Website Url"
        type="url"
        value={websiteUrl}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, websiteUrl: val })
        }
      />
      <Input
        label="Facebook Url"
        type="url"
        value={facebookUrl}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, facebookUrl: val })
        }
      />
      <Input
        label="Email"
        type="email"
        value={email}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, email: val })
        }
      />
      <Input
        label="Description"
        type="text"
        inputType="textarea"
        value={description}
        onChange={(val) =>
          setProfileEditData({ ...profileEditData, description: val })
        }
      />
      {isLoading && 'Loading categories'}
      {!isLoading && categoryList && (
        <PillSelect
          onChange={(val) =>
            setProfileEditData({ ...profileEditData, categories: val })
          }
          fieldName="Categories"
          selectedFields={categories}
          list={categoryList}
        />
      )}
      <div style={{ paddingTop: 60 }}>
        <p>
          Optional: Grateful will match your organization with donors if you
          provide details about ongoing financial needs. For example, "for every
          $___ you can sponsor/support a [family, child, animal, program, etc.
          whatever is appropriate for your organization]"
        </p>
      </div>

      {[1, 2, 3].map((key) => (
        <NeedForm
          key={key}
          needHeader={`Ongoing Need #${key}`}
          needKey={key}
          dynamicObjectUpdate={dynamicObjectUpdate}
          alertNeeds={alertNeeds[key - 1]}
          setProfileEditData={setProfileEditData}
          profileEditData={profileEditData}
          needFileChangeHandler={needFileChangeHandler}
        />
      ))}
    </>
  );
}
