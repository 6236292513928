import styled from '@emotion/styled';
import Input from './Input';

const ImageUploadContainer = styled.div`
  display: grid;
  grid-gap: 0.5em;
  align-items: center;
`;

export default function ImageUpload({ image, setImage }) {
  const handleChange = (file) => {
    const fileUrl = URL.createObjectURL(file);
    setImage({ file: file, localUrl: fileUrl });
  };

  return (
    <ImageUploadContainer>
      <Input type="file" onChange={handleChange} captureFiles />

      {image.localUrl && (
        <img
          src={image.localUrl}
          alt="Nonprofit logo preview"
          style={{ maxWidth: 300, maxHeight: 300 }}
        />
      )}
    </ImageUploadContainer>
  );
}
