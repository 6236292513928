/* eslint-disable jsx-a11y/anchor-is-valid */
// https://material-ui.com/components/buttons/#customized-buttons
import { useState } from 'react';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  APPROVE,
  CONTACT_ME_MODAL,
  CANCEL_EDIT,
  REMOVE_MODAL,
} from '../constants';

import { ButtonContainer } from './Styled';
import { GRATEFUL_FAQ_URL } from '../constants';

const buttonStyles = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-around',
};

export default function ViewOptions({
  edit,
  setEdit,
  editError,
  setModalStates,
}) {
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  // TODO: remove inline styles
  return (
    <ButtonContainer>
      <button
        className="btn btn-big btn-green mt-0 w-button"
        style={buttonStyles}
        onClick={() => {
          if (!editError) {
            setModalStates({ type: APPROVE, open: true });
          } else {
            setOpenErrorSnackbar(true);
            setTimeout(() => {
              setOpenErrorSnackbar(false);
            }, 10000);
          }
        }}
      >
        <CheckIcon />
        {edit ? 'Save changes and approve' : 'Approve, looks good'}
      </button>
      <button
        onClick={() => {
          if (edit) {
            setModalStates({ type: CANCEL_EDIT, open: true });
          } else {
            setEdit(true);
          }
        }}
        style={buttonStyles}
        className="btn btn-big btn-secondary w-button"
      >
        {!edit && (
          <>
            <EditIcon />
            <span>Make changes</span>
          </>
        )}
        {edit && (
          <>
            <ClearIcon />
            Cancel changes
          </>
        )}
      </button>
      <button
        className="btn btn-big btn-secondary w-button"
        style={buttonStyles}
        onClick={() => setModalStates({ type: REMOVE_MODAL, open: true })}
      >
        <DeleteIcon />
        <span>Request removal</span>
      </button>
      <div className="p-big" style={{ paddingTop: 10, textAlign: 'center' }}>
        <p>
          Questions?
          <br />
          Review our{' '}
          <a href={GRATEFUL_FAQ_URL} target="_blank" rel="noreferrer">
            FAQs
          </a>{' '}
          or{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setModalStates({ type: CONTACT_ME_MODAL, open: true });
            }}
          >
            contact us
          </a>{' '}
        </p>
      </div>
      <Portal>
        <Snackbar
          onClick={() => setOpenErrorSnackbar(false)}
          open={openErrorSnackbar}
        >
          <Alert severity="error" variant="filled">
            Please fix the form errors before submitting your changes.
          </Alert>
        </Snackbar>
      </Portal>
    </ButtonContainer>
  );
}
