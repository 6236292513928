import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";

import { REMOVED_ACTION, GRATEFUL_EMAIL, GRATEFUL_APP_URL } from '../constants';
import NonprofitProfile from './NonprofitProfile';

const useStyles = makeStyles({
  successAlert: {
    "& .MuiAlert-message": {
      fontSize: 19
    }
  }
});

export default function NonprofitActionHandler() {
  const classes = useStyles();

  // get nonprofitId from URL param
  let { nonprofitId } = useParams();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openSpinner, setOpenSpinner] = useState(false);

  const closeAllSnackbars = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSpinner(false);
    setOpenRemove(false);
    setOpenSuccess(false);
    setOpenFailure(false);
  };

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? '/test-given-functions/us-central1'
      : '/function';

  const createAction = useMutation(
    (actionPayload) => {
      return fetch(`${baseUrl}/createNonprofitUiAction`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        body: JSON.stringify(actionPayload),
      });
    },
    {
      onMutate: () => {
        closeAllSnackbars();
        setOpenSpinner(true);
      },
      onError: () => {
        closeAllSnackbars();
        setOpenFailure(true);
      },
      onSuccess: (data, variables) => {
        closeAllSnackbars();

        const action = variables.actionPayload.action;
        if (action === REMOVED_ACTION) {
          setOpenRemove(true);
        } else {
          setOpenSuccess(true);
        }
      },
    }
  );

  return (
    <>
      <NonprofitProfile nonprofitId={nonprofitId} createAction={createAction} />

      {/* Removal Snackbar */}
      <Snackbar open={openRemove} onClose={closeAllSnackbars}>
        <Alert onClose={closeAllSnackbars} severity="warning" variant="filled">
          This profile has been flagged for removal.
        </Alert>
      </Snackbar>

      {/* Approve Success (and Contact Me) Snackbar */}
      <Snackbar open={openSuccess} onClose={closeAllSnackbars}>
        <Alert onClose={closeAllSnackbars} severity="success" variant="filled" className={classes.successAlert}>
          Thank you! You can manage your profile in the future by logging in to
          the <a href={GRATEFUL_APP_URL}>Grateful app</a> with your email and
          password. If you need help, email us at{' '}
          <a
            href={`mailto:${GRATEFUL_EMAIL}?subject=Concerning ${nonprofitId}`}
          >
            {GRATEFUL_EMAIL}
          </a>
          .
        </Alert>
      </Snackbar>

      {/* Something went wrong snackbar */}
      <Snackbar open={openFailure} onClose={closeAllSnackbars}>
        <Alert onClose={closeAllSnackbars} severity="error" variant="filled">
          Oh, no! Something went wrong! You can try your submission again, or
          feel free to contact us directly at{' '}
          <a
            style={{ color: 'blue' }}
            href={`mailto:${GRATEFUL_EMAIL}?subject=Concerning error with confirming ${nonprofitId}`}
          >
            {GRATEFUL_EMAIL}
          </a>
          .
        </Alert>
      </Snackbar>

      {/* Loading Snackbar */}
      <Snackbar open={openSpinner}>
        <CircularProgress />
      </Snackbar>
    </>
  );
}
