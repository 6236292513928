import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  div {
    width: 100%;
  }

  div#action-icons {
    padding: 0 10px;
  }
`;

const StyledCardContent = styled(CardContent)`
  /* display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(250, 250, 250);

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  label {
    font-weight: 600;
    width: 150px;
    min-width: 150px;
  } */
`;

export default function NonprofitProfileView({ profile, nonprofitId }) {
  const [profileData, setProfileData] = useState(() => profile);

  useEffect(() => setProfileData(profile), [profile]);

  const {
    name,
    logo,
    streetAddress1,
    streetAddress2,
    city,
    zipCode,
    state,
    taxId,
    websiteUrl,
    facebookUrl,
    email,
    description,
    categories,
    metroAreas,
    needTitle1,
    needAmount1,
    needDescription1,
    needImage1,
    needId1,
    needTitle2,
    needAmount2,
    needDescription2,
    needImage2,
    needId2,
    needTitle3,
    needAmount3,
    needDescription3,
    needImage3,
    needId3,
  } = profileData;

  return (
    <div className="mb-0 w-form">
      <form
        id="wf-form-Example-Form"
        name="wf-form-Example-Form"
        data-name="Example Form"
        className="form"
      >
        <div className="mock-profile_static">
          <ImageContainer>
            <div className="input-group w-embed">
              <Card>
                <StyledCardContent>
                  <label>Logo:</label>
                  <img src={logo} alt={name} width={100} />
                </StyledCardContent>
              </Card>
            </div>
          </ImageContainer>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Name:</label>
                {name}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Address:</label>
                <div>
                  <p>{streetAddress1}</p>
                  <p>{streetAddress2}</p>
                  <p>{city}</p>
                  <p>{zipCode}</p>
                  <p>{state}</p>
                </div>
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Tax ID:</label> {taxId}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Website Url:</label> {websiteUrl}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Facebook Url:</label> {facebookUrl}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Email:</label> {email}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Description:</label> {description}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Categories:</label>{' '}
                {categories?.length > 0
                  ? categories.join(', ').replace(/,(?!.*,)/gim, ' and')
                  : ''}
              </StyledCardContent>
            </Card>
          </div>
          <div>
            <p>
              Optional: Grateful will match your organization with donors if you provide details about ongoing financial needs. For example, "for every $___ you can sponsor/support a [family, child, animal, program, etc. whatever is appropriate for your organization]"
            </p>
          </div>
          <div className="input-group w-embed" style={{ paddingTop: 10 }}>
            <Card>
              <StyledCardContent>
                <label>Ongoing Need #1:</label> {needTitle1}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Ongoing Need #2:</label> {needTitle2}
              </StyledCardContent>
            </Card>
          </div>
          <div className="input-group w-embed">
            <Card>
              <StyledCardContent>
                <label>Ongoing Need #3:</label> {needTitle3}
              </StyledCardContent>
            </Card>
          </div>
          {/* <div className="input-group w-embed">
          <Card>
            <StyledCardContent>
              <label>Metro Areas:</label>{' '}
              {metroAreas?.length > 0
                ? metroAreas.join(', ').replace(/,(?!.*,)/gim, ' and')
                : ''}
            </StyledCardContent>
          </Card>
        </div> */}
        </div>
      </form>
    </div>
  );
}
