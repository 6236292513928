import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Input from './Input';

const NeedForm = ({
  needHeader,
  needKey,
  dynamicObjectUpdate,
  alertNeeds,
  setProfileEditData,
  profileEditData,
  needFileChangeHandler,
}) => {
  return (
    <div className="input-group w-embed">
      <p>
        <strong>{needHeader}</strong>
      </p>
      <Input
        label="Need Title"
        type="text"
        value={profileEditData[`needTitle${needKey}`]}
        onChange={(val) =>
          dynamicObjectUpdate(
            profileEditData,
            setProfileEditData,
            `needTitle${needKey}`,
            val
          )
        }
      />
      {alertNeeds.title && <Alert severity="error">Please add a title.</Alert>}
      <Input
        label="$ Amount"
        type="text"
        value={profileEditData[`needAmount${needKey}`]}
        onChange={(val) =>
          dynamicObjectUpdate(
            profileEditData,
            setProfileEditData,
            `needAmount${needKey}`,
            val
          )
        }
      />
      {alertNeeds.amount && (
        <Alert severity="error">Please add an amount.</Alert>
      )}
      {alertNeeds.numbersOnly && (
        <Alert severity="error">Amount should be numbers only, no other characters.</Alert>
      )}
      <Input
        label="Need Description"
        type="text"
        inputType="textarea"
        value={profileEditData[`needDescription${needKey}`]}
        onChange={(val) =>
          dynamicObjectUpdate(
            profileEditData,
            setProfileEditData,
            `needDescription${needKey}`,
            val
          )
        }
      />
      {alertNeeds.description && (
        <Alert severity="error">Please add a description.</Alert>
      )}
      <p>Upload Video or Image:</p>
      <Input
        type="file"
        onChange={(file) => needFileChangeHandler(file, needKey)}
        captureFiles
      />
      {alertNeeds.file && (
        <Alert severity="error">Please add a video or image.</Alert>
      )}
      {alertNeeds.fileType && (
        <Alert severity="error">File should be image (JPG, PNG, GIF) or video (MP4, MOV) only.</Alert>
      )}
    </div>
  );
};

export default NeedForm;
