import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// this import needs to happen for firebase to initializeApp
// probably a better way to do this
import { firebaseApp } from './firebase';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './style.css';
import NonprofitActionHandler from './components/NonprofitActionHandler';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route path="/np/:nonprofitId">
            <NonprofitActionHandler />
          </Route>
          <Route path="/">Homepage</Route>
        </Switch>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
