import styled from '@emotion/styled';

const LogoutContainer = styled.div`
  height: 50px;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 150px;
  align-items: center;
`;

export default function Logout({ onClick, user }) {
  return (
    <LogoutContainer>
      <div>
        You are logged in as <em>{user.email}.</em> <br /> Logout to approve
        this as another user.
      </div>
      <button
        className="btn btn-secondary w-button"
        type="button"
        onClick={onClick}
      >
        Logout
      </button>
    </LogoutContainer>
  );
}
