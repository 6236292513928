import React from 'react';

import TransitionsModal from './TransitionModal';
import { ButtonContainer } from '../Styled';
import { CANCEL_EDIT } from '../../constants';

export default function CancelEditModal({
  modalStates,
  setModalStates,
  setEdit,
}) {
  return (
    <TransitionsModal open={modalStates.cancelEdit}>
      <h3 id="transition-modal-title">Cancel Edit</h3>
      Changes will not be saved. Continue with cancel?
      <ButtonContainer buttonWidth="80px">
        <button
          className="btn w-button"
          onClick={() => setModalStates({ type: CANCEL_EDIT, open: false })}
        >
          <span>Continue editing</span>
        </button>
        <button
          className="btn btn-secondary w-button"
          onClick={() => {
            setEdit(false);
            setModalStates({ type: CANCEL_EDIT, open: false });
          }}
        >
          <span>Cancel edits</span>
        </button>
      </ButtonContainer>
    </TransitionsModal>
  );
}
