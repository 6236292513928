import firebase from 'firebase/app';
import 'firebase/auth';

import { PROD_URL } from './constants';

let firebaseConfig;

// Dynamically load the config depending on environment
if (window.location.host.indexOf(PROD_URL) >= 0) {
  // prod
  firebaseConfig = {
    apiKey: 'AIzaSyDx-nzuVl33-O6AUxhLIVAN_pu46eskvaU',
    authDomain: 'given-e6ba8.firebaseapp.com',
    databaseURL: 'https://given-e6ba8.firebaseio.com',
    projectId: 'given-e6ba8',
    storageBucket: 'given-e6ba8.appspot.com',
    messagingSenderId: '210872176625',
    appId: '1:210872176625:web:b8a25b9b69be7bd9193e50',
  };
} else {
  // test
  firebaseConfig = {
    apiKey: 'AIzaSyCCHG5onuMCByhRDC4x4Xz8UlAm6iDCLqQ',
    authDomain: 'given-test.firebaseapp.com',
    databaseURL: 'https://given-test.firebaseio.com',
    projectId: 'given-test',
    storageBucket: 'given-test.appspot.com',
    messagingSenderId: '45271752917',
    appId: '1:45271752917:web:6472c72731e7f13595c25c',
    measurementId: 'G-3324EFSLDZ',
  };
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

export { firebaseApp };
