import styled from '@emotion/styled';

import Input from '../Input';

const LoginButtonContainer = styled.div`
  height: 100px;
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
`;

export default function Login({
  setAuthState,
  loginUsername,
  setLoginUsername,
  loginPassword,
  setLoginPassword,
}) {
  return (
    <>
      Login with your Grateful account to approve this profile
      <Input
        label="Your email"
        type="email"
        name="loginUsername"
        value={loginUsername}
        onChange={(email) => {
          setLoginUsername(email);
        }}
      />
      <Input
        label="Password"
        type="password"
        name="password"
        value={loginPassword}
        onChange={(loginPassword) => {
          setLoginPassword(loginPassword);
        }}
      />
      <LoginButtonContainer>
        <div>
          Don't have an account?{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setAuthState('sign-up');
            }}
          >
            Create an account
          </a>
        </div>
      </LoginButtonContainer>
    </>
  );
}
