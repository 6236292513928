import styled from '@emotion/styled';

export const ButtonContainer = styled.div`
  margin: 20px 0;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(props) => props.buttonWidth || '180px'}, 1fr)
  );
`;
