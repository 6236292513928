// When we are ready for login/logout functionality:
// https://jasonwatmore.com/post/2020/03/02/react-hooks-redux-user-registration-and-login-tutorial-example
// https://serverless-stack.com/chapters/create-a-login-page.html

import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';

import { REMOVE_MODAL, REMOVED_ACTION } from '../../constants';

import TransitionsModal from './TransitionModal';
import { ButtonContainer } from '../Styled';
import Input from '../Input';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const CheckboxGroup = styled.div`
  height: 50px;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 100px 1fr;
  align-items: center;
`;

export default function RemoveModal({
  modalStates,
  setModalStates,
  createAction,
  profile,
  nonprofitId,
}) {
  const [reasonDetail, setReasonDetail] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [reasonRadio, setReasonRadio] = useState('notInOperation');
  const [error, setError] = useState();

  const validate = () => {
    if (!name || !email) {
      setError('Name and email are required.');
      return false;
    }
    return true;
  };

  const removeAction = (e) => {
    e.preventDefault();

    const isValid = validate();

    if (isValid) {
      createAction.mutate({
        actionPayload: {
          nonprofitId: nonprofitId,
          action: REMOVED_ACTION,
          nonprofitName: profile.name,
          submitterName: name,
          email: email,
          reason: `${reasonRadio}${
            reasonRadio === 'other' ? ': ' + reasonDetail : ''
          }`,
        },
      });

      // reset to defaults
      setReasonDetail('');
      setName('');
      setEmail('');
      setReasonRadio('notInOperation');
      setError();

      setModalStates({ type: REMOVE_MODAL, open: false });
    }
  };

  return (
    <TransitionsModal open={modalStates.remove}>
      <form onSubmit={removeAction}>
        <h3 id="transition-modal-title" style={{ minWidth: 350 }}>
          Request removal
        </h3>
        <RadioGroup
          aria-label="reason"
          name="reason"
          value={reasonRadio}
          onChange={(event) => setReasonRadio(event.target.value)}
        >
          <FormControlLabel
            value="notInOperation"
            control={<Radio />}
            label="No longer in operation"
          />
          <FormControlLabel
            value="notSeekingDonations"
            control={<Radio />}
            label="Not seeking donations"
          />
          <CheckboxGroup>
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="Other"
            ></FormControlLabel>
            <Input
              disabled={reasonRadio !== 'other'}
              label="Reason"
              type="text"
              value={reasonDetail}
              collapseMargin
              onChange={(reason) => setReasonDetail(reason)}
            />
          </CheckboxGroup>
        </RadioGroup>
        <Input
          label="Your name"
          type="text"
          name="name"
          value={name}
          onChange={(name) => setName(name)}
        />
        <Input
          label="Your email"
          type="email"
          name="username"
          value={email}
          onChange={(email) => setEmail(email)}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <ButtonContainer buttonWidth="80px">
          <button className="btn w-button">
            <span>Confirm</span>
          </button>
          <button
            className="btn btn-secondary w-button"
            onClick={() => setModalStates({ type: REMOVE_MODAL, open: false })}
          >
            <span>Cancel</span>
          </button>
        </ButtonContainer>
      </form>
    </TransitionsModal>
  );
}
