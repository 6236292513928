import styled from '@emotion/styled';
import Input from '../Input';

const CreateAccountContainer = styled.div`
  height: 100px;
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
`;

export default function CreateAccount({
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  setAuthState,
}) {
  return (
    <>
      Create an account with Grateful to approve this profile
      <Input
        label="Your name"
        type="text"
        name="name"
        value={name}
        onChange={(name) => setName(name)}
      />
      <Input
        label="Your email"
        type="email"
        name="username"
        value={email}
        onChange={(email) => setEmail(email)}
      />
      <Input
        label="Select password"
        type="password"
        name="password"
        value={password}
        onChange={(password) => setPassword(password)}
      />
      <CreateAccountContainer>
        <div>
          Already have an account?{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setAuthState('login');
            }}
          >
            Login
          </a>
        </div>
      </CreateAccountContainer>
    </>
  );
}
