import React from 'react';
import { useState, useReducer, useEffect } from 'react';
import { useQuery } from 'react-query';
import Alert from '@material-ui/lab/Alert';

import {
  APPROVE,
  CONTACT_ME_MODAL,
  CANCEL_EDIT,
  REMOVE_MODAL,
} from '../constants';
import ViewOptions from './ViewOptions';
import NonprofitProfileEdit from './NonprofitProfileEdit';
import NonprofitProfileView from './NonprofitProfileView';

import ApproveModal from './modals/ApproveModal';
import RemoveModal from './modals/RemoveModal';
import CancelEditModal from './modals/CancelEditModal';
import ContactMeModal from './modals/ContactMeModal';

import Navbar from './Navbar';
import Footer from './Footer';

import { GRATEFUL_FAQ_URL } from '../constants';

const modalReducer = (state, action) => {
  switch (action.type) {
    case APPROVE: {
      return { ...state, approve: action.open };
    }
    case CONTACT_ME_MODAL: {
      return { ...state, call: action.open };
    }
    case CANCEL_EDIT: {
      return { ...state, cancelEdit: action.open };
    }
    case REMOVE_MODAL: {
      return { ...state, remove: action.open };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
};

export default function NonprofitProfile({ nonprofitId, createAction }) {
  // edit controls whether the profile view is in edit or view mode.
  const [edit, setEdit] = useState(false);
  // these variables store the data edited in the form
  const [profileEditData, setProfileEditData] = useState();

  // false = not open. Use setModalStates with the action types listed in the reducer to change the modal state.
  const [modalStates, setModalStates] = useReducer(modalReducer, {
    approve: false,
    call: false,
    remove: false,
    cancelEdit: false,
    success: false,
  });

  // useQuery is a package to help with loading
  const { isLoading, error, data: profile } = useQuery(
    'nonprofitData',
    async () => {
      // uses local server if local, otherwise sets to function for Firebase mapping (see firebase.json: rewrites)
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? '/test-given-functions/us-central1'
          : '/function';
      const res = await fetch(
        `${baseUrl}/getNonprofitData/?nonprofitId=${nonprofitId}`
      );
      if (!res.ok) {
        throw new Error('Unable to load data or profile has been removed. Contact Grateful if you need help.');
      }
      return res.json();
    }
  );

  const [editError, setEditError] = useState();

  useEffect(() => {
    setProfileEditData(profile);
  }, [profile]);

  const NonprofitDisclaimer = () => (
    <div className="header-description">
      <div className="p-big">
        <p>
          Grateful is a platform for businesses and individuals to share giving
          cards. Card recipients can donate the proceeds to any 501(c)(3).
          Please review the profile that we automatically created for your
          organization based on publicly available information, then choose from
          the actions below.
        </p>
        <p>
          <strong>
            There is no cost to your organization to have a profile.
          </strong>
          <br />
          If you have any questions, please review our{' '}
          <a href={GRATEFUL_FAQ_URL}>FAQs</a> or{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setModalStates({ type: CONTACT_ME_MODAL, open: true });
            }}
          >
            contact us
          </a>{' '}
          to speak with a Grateful representative.
        </p>
      </div>
    </div>
  );

  // NOTE: All the `className` styles are brought in from Webflow css import (see index.html)
  return (
    <div style={{ paddingTop: 100 }}>
      <Navbar setModalStates={setModalStates} />
      <div className="container narrow text-center">
        <div className="h3 mb-2 text-primary">
          NEW&nbsp;GRATEFUL&nbsp;PROFILE
        </div>
        <h1 className="header-title">
          {profile && profile.name && `${profile.name}`}
        </h1>
        <NonprofitDisclaimer />
      </div>

      <section className="section overflow-visible">
        <div className="container">
          <div className="card overflow-visible bg-white">
            <div className="card-body">
              {/* handle loading state */}
              {isLoading && !error && 'Loading nonprofit data, please wait...'}

              {/* handle error state */}
              {error && 'An error has occurred: ' + error.message}

              {/* normal state after load */}
              {!isLoading && !error && (
                <div className="row align-start position-relative">
                  {/* zIndex in class styles is 9999, needs to not be over modal */}
                  <div
                    className="col col-lg-4 col-sm-12 mock-profile_sticky"
                    style={{ zIndex: 1 }}
                  >
                    <div className="mock-profile_actions">
                      <ViewOptions
                        modalStates={modalStates}
                        setModalStates={setModalStates}
                        editError={editError}
                        setEdit={setEdit}
                        edit={edit}
                        nonprofitId={nonprofitId}
                      />
                    </div>
                  </div>
                  <div className="col col-lg-8 col-sm-12">
                    {edit && editError && (
                      <div style={{ paddingBottom: 20 }}>
                        <Alert severity="error">
                          <p>
                            To submit your changes, please fix the following
                            errors:
                          </p>
                          <ul>
                            {editError.map((error) => (
                              <li>{error}</li>
                            ))}
                          </ul>
                        </Alert>
                      </div>
                    )}
                    {edit && (
                      <NonprofitProfileEdit
                        profileEditData={profileEditData}
                        setProfileEditData={setProfileEditData}
                        setEdit={setEdit}
                        setEditError={setEditError}
                        nonprofitId={nonprofitId}
                        profile={profile}
                        modalStates={modalStates}
                        setModalStates={setModalStates}
                      />
                    )}
                    {!edit && (
                      <NonprofitProfileView
                        nonprofitId={nonprofitId}
                        profile={profile}
                        modalStates={modalStates}
                        setModalStates={setModalStates}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <ApproveModal
        profileEditData={profileEditData}
        profile={profile}
        nonprofitId={nonprofitId}
        edit={edit}
        createAction={createAction}
        modalStates={modalStates}
        setModalStates={setModalStates}
      />
      <RemoveModal
        createAction={createAction}
        profile={profile}
        nonprofitId={nonprofitId}
        modalStates={modalStates}
        setModalStates={setModalStates}
      />
      <ContactMeModal
        createAction={createAction}
        profile={profile}
        nonprofitId={nonprofitId}
        modalStates={modalStates}
        setModalStates={setModalStates}
      />
      <CancelEditModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        setEdit={setEdit}
      />
      <Footer />
    </div>
  );
}
