// from: https://dev.to/jastornaut/plaid-inspired-inputs-with-react-hooks-and-styled-components-1h93
import React from 'react';
import styled from '@emotion/styled';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.collapseMargin ? '0' : '20px 0 10px 0')};
  position: relative;

  & > input,
  & > textarea {
    border: 1px solid ${(props) => (props.error ? '#e77674' : '#eee')};
    border-radius: 0.25rem;
    background-color: transparent;
    outline: none;
    padding: 12px 3px 12px 15px;
    font-size: 16px;
    transition: all 0.2s ease;
    z-index: 500;
  }
  & > textarea {
    min-height: 100px;
    font-family: roboto;
  }
  & > label {
    color: #757575;
    position: absolute;
    top: 15px;
    left: 15px;
    transition: all 0.2s ease;
    z-index: 500;

    ${(props) =>
      props.focused &&
      `
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 501;
      background: white;
      padding: 0 8px;
    `}
  }
`;

/**
 * A Plaid-inspired custom input component
 *
 * @param {string} value - the value of the controlled input
 * @param {string} type - the type of input we'll deal with
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 */

const Input = ({
  value,
  type,
  name,
  label,
  children,
  onChange,
  onFocus,
  onBlur,
  setRef,
  collapseMargin,
  required,
  captureFiles,
  inputType = 'input',
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleOnFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleOnBlur = () => {
    setFocused(false);
    onBlur();
  };

  const validateValue = (val) => {
    if (type === 'email') {
      // VERY simple email validation
      if (val.indexOf('@') === -1) {
        setError('email is invalid');
      } else {
        setError(null);
      }
    }

    if (required) {
      if (!val) {
        setError('This field is required');
      }
    }

    // ... any other validation you could think of
    // ... maybe even pass in an additional validation function as a prop?
  };

  const handleOnChange = (val) => {
    validateValue(val);
    onChange(val);
  };

  const eventCapture = (e) => {
    if (captureFiles) {
      return e.target.files[0];
    } else {
      return e.target.value;
    }
  };

  const renderLabel = () => {
    if (label) {
      // if we have an error
      if (error) {
        return <label>{error}</label>;
      }

      return <label>{label}</label>;
    }
    return null;
  };

  const isFocused = focused || String(value).length || type === 'date';

  return (
    <InputContainer
      collapseMargin={collapseMargin}
      focused={isFocused}
      error={error}
    >
      {children}
      {renderLabel()}
      {inputType === 'input' && (
        <input
          value={value}
          required={required}
          type={type}
          name={name}
          onChange={(e) => handleOnChange(eventCapture(e))}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          ref={(ref) => setRef(ref)}
          {...props}
        />
      )}
      {inputType === 'textarea' && (
        <textarea
          value={value}
          type={type}
          name={name}
          onChange={(e) => handleOnChange(e.target.value)}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          ref={(ref) => setRef(ref)}
          {...props}
        />
      )}
    </InputContainer>
  );
};

Input.defaultProps = {
  type: 'text',
  label: '',
  onChange: (text) => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {},
};

export default Input;
